import React from "react";
import moment from "moment";
import { Progress } from "antd";
import type { ProgressProps } from 'antd';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#108ee9',
  '100%': '#87d068',
};

interface LoanProgressProps {
  created_at: string;
  expires_at: string;
}

const LoanProgress: React.FC<LoanProgressProps> = ({ created_at, expires_at }) => {
  const startDate = moment.utc(created_at);
  const endDate = moment.utc(expires_at);
  const currentDate = moment.utc();

  const totalDuration = endDate.diff(startDate);

  const elapsedDuration = currentDate.diff(startDate);

  const progressPercent = Math.min(
    Math.max(Math.floor((elapsedDuration / totalDuration) * 100), 0),
    100
  );

  return (
    <div>
      <div className="progress_bar">
        <Progress
          percent={progressPercent}
          //status={progressPercent === 100 ? "success" : "active"}
          showInfo
          strokeColor={twoColors}
        />
      </div>
    </div>
  );
};

export default LoanProgress;
