import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Radio, Typography, List } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";

const { Title, Paragraph } = Typography;

const Language: FC = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e: RadioChangeEvent) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage);
  };


  const languages = [
    { code: "en", label: "English" },
    { code: "zh", label: "Chinese (Simplified)" },
    { code: "hi", label: "Hindi" },
    { code: "es", label: "Spanish" },
    { code: "fr", label: "French" },
    { code: "ar", label: "Arabic" },
    { code: "bn", label: "Bengali" },
    { code: "ru", label: "Russian" },
    { code: "pt", label: "Portuguese" },
    { code: "id", label: "Indonesian" },
    { code: "it", label: "Italian" },
    { code: "de", label: "German" },
  ];

  return (
    <>
      <Title level={3}>{t("settings")}</Title>
      <form>
        <List
          header={<Paragraph>{t("switchLanguage")}</Paragraph>}
          bordered
          style={{ marginBottom: "20px" }}
        >
          {languages.map((lang) => (
            <List.Item key={lang.code}>
              <Radio.Group
                onChange={handleLanguageChange}
                value={i18n.language}
              >
                <Radio value={lang.code}>{t(lang.label)}</Radio>
              </Radio.Group>
            </List.Item>
          ))}
        </List>
      </form>
    </>
  );
};

export default Language;
