import { FC } from "react";
import { Progress } from "antd";
import type { ProgressProps } from "antd";

const twoColors: ProgressProps["strokeColor"] = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const Rating: FC<{ rating: number }> = ({ rating }) => {


  return <Progress type="dashboard" percent={rating} strokeColor={twoColors} style={{marginTop: 30}}/>;
};

export default Rating;
