import { FC, useState, useEffect } from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import { useTranslation } from "react-i18next";
import { MainButton } from "@twa-dev/sdk/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import { sendTransaction } from "../helpers/transactionService";
import { useSelector, useDispatch } from "react-redux";
import { updateBonusBalance } from "../services/slices/userSlice";
import { RootState, AppDispatch } from "../services/store";
import { getPaymentWallet } from "../helpers/api";
import {
  InputNumber,
  Form,
  Switch,
  Typography,
  message,
  Divider,
  Checkbox,
  Select,
  Radio,
  Alert,
} from "antd";
import { BulbTwoTone } from "@ant-design/icons";

const { Title, Text, Link } = Typography;
const { Option } = Select;

const LoanIssuance: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const tonPrice = useSelector((state: RootState) => state.ton.tonPrice);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const mode = useSelector((state: RootState) => state.app.mode);
  const [useBonus, setUseBonus] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [rate, setRate] = useState<number | null>(null);
  const [term, setTerm] = useState<number | null>(null);
  const [insurance, setInsurance] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [marginLoan, setMarginLoan] = useState<number>(0);
  const [tokenType, setTokenType] = useState("USDT");

  const [errors, setErrors] = useState<{
    amount?: string;
    rate?: string;
    term?: string;
  }>({});
  const [messageText, setMessageText] = useState<React.ReactNode>("");
  const popularTerms = insurance ? [30, 90, 180, 365] : [15, 30, 90];

  useEffect(() => {
    if (location.state?.useBonus) {
      setUseBonus(true);
    }
    if (location.state?.tokenType) {
      setTokenType("TON");
    }
  }, [location.state]);

  useEffect(() => {
    if (useBonus && userData) {
      setTerm(14);
      setAmount(userData.bonus_balance);
      setRate(0.21);
    }
    if (amount && rate && term) {
      const margin = Math.min(amount * (marginLoan / 100), 3000);
      const profit = amount * (rate / 100) * term;
      const marginProfit = margin * (rate / 100) * term;
      const totalProfit = profit + marginProfit;
      const insuranceAmount = insurance
        ? totalProfit * 0.25
        : totalProfit * 0.05;
      const marginPercent =
        margin * (Number(userData?.settings.margin_rate) / 100) * term;
      const total_profit = totalProfit - insuranceAmount - marginPercent;

      let bonuses: [number, number, [number, number, number][]][] =
        tokenType === "TON" && insurance
          ? [
              [
                30,
                90,
                [
                  [1, 10, 0.5],
                  [10, 20, 0.75],
                  [20, 40, 1],
                  [40, 60, 1.25],
                  [60, 80, 1.5],
                  [80, 100, 1.75],
                  [100, 500, 2],
                  [500, Infinity, 2.5],
                ],
              ],
              [
                90,
                180,
                [
                  [1, 10, 1],
                  [10, 20, 1.25],
                  [20, 40, 1.5],
                  [40, 60, 1.75],
                  [60, 80, 2],
                  [80, 100, 2.5],
                  [100, 500, 3],
                  [500, Infinity, 3.5],
                ],
              ],
              [
                180,
                365,
                [
                  [1, 10, 1.5],
                  [10, 20, 2],
                  [20, 40, 2.5],
                  [40, 60, 3],
                  [60, 80, 3.5],
                  [80, 100, 4],
                  [100, 500, 4.5],
                  [500, Infinity, 5],
                ],
              ],
              [
                365,
                Infinity,
                [
                  [1, 10, 3],
                  [10, 20, 4],
                  [20, 40, 5],
                  [40, 60, 6],
                  [60, 80, 7],
                  [80, 100, 8],
                  [100, 500, 9],
                  [500, Infinity, 10],
                ],
              ],
            ]
          : [
              [
                30,
                90,
                [
                  [1, 50, 0.5],
                  [50, 100, 0.75],
                  [100, 200, 1],
                  [200, 300, 1.25],
                  [300, 400, 1.5],
                  [400, 500, 1.75],
                  [500, 2500, 2],
                  [2500, Infinity, 2.5],
                ],
              ],
              [
                90,
                180,
                [
                  [1, 50, 1],
                  [50, 100, 1.25],
                  [100, 200, 1.5],
                  [200, 300, 1.75],
                  [300, 400, 2],
                  [400, 500, 2.5],
                  [500, 2500, 3],
                  [2500, Infinity, 3.5],
                ],
              ],
              [
                180,
                365,
                [
                  [1, 50, 1.5],
                  [50, 100, 2],
                  [100, 200, 2.5],
                  [200, 300, 3],
                  [300, 400, 3.5],
                  [400, 500, 4],
                  [500, 2500, 4.5],
                  [2500, Infinity, 5],
                ],
              ],
              [
                365,
                Infinity,
                [
                  [1, 50, 3],
                  [50, 100, 4],
                  [100, 200, 5],
                  [200, 300, 6],
                  [300, 400, 7],
                  [400, 500, 8],
                  [500, 2500, 9],
                  [2500, Infinity, 10],
                ],
              ],
            ];

      let bonus: number | undefined;

      for (let [minTerm, maxTerm, amountRanges] of bonuses) {
        if (term >= minTerm && term < maxTerm) {
          for (let [minAmount, maxAmount, factor] of amountRanges) {
            if (amount >= minAmount && amount < maxAmount) {
              bonus = amount * factor;
              break;
            }
          }
          break;
        }
      }

      if (tokenType === "TON" && bonus && tonPrice) {
        bonus *= tonPrice;
      }

      setMessageText(
        <>
          {t("Your profit will be")} {useBonus ? "$" : ""}
          {total_profit.toFixed(2)} {useBonus ? "" : tokenType}
          {!useBonus && term >= 30 && insurance && (
            <span style={{ color: "green", display: "block" }}>
              {" "}
              + ${bonus?.toFixed(2)} Bonus
            </span>
          )}
        </>
      );
    }
  }, [
    amount,
    rate,
    term,
    insurance,
    t,
    useBonus,
    tokenType,
    marginLoan,
    userData,
    tonPrice,
  ]);

  const selectBefore = (
    <Select
      value={tokenType}
      onChange={(value) => setTokenType(value)}
      style={{ width: 100 }}
    >
      <Option value="USDT">
        <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={12} /> USDT
      </Option>
      <Option value="TON">
        <img src="./assets/tonIcon.png" alt="TON Logo" width={12} /> TON
      </Option>
    </Select>
  );

  const handleSubmit = async () => {
    setDisabled(true);
    let validationErrors: { amount?: string; rate?: string; term?: string } =
      {};

    // Validate amount
    if (amount === null || amount <= 0) {
      validationErrors.amount = t("Please enter a valid amount");
    }

    // Validate rate
    if (rate === null || rate <= 0) {
      validationErrors.rate = t("Please enter a valid rate");
    }

    // Validate term only if not using bonus
    if (!useBonus) {
      if (term === null || term < 3) {
        validationErrors.term = t("Please enter a valid term");
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setDisabled(false);
      return;
    }

    const loanData = {
      amount,
      rate,
      term: useBonus ? 14 : term,
      useBonus,
      insurance,
    };

    if (useBonus) {
      try {
        axios
          .post("/api/issue-loan.php", loanData)
          .then((response) => {
            if (response.data.error) {
              message.error(t(response.data.error));
            } else {
              dispatch(updateBonusBalance(-amount!));
              message.success(t("Loan issued successfully"));
              setDisabled(false);
              setAmount(null);
              setRate(null);
              setTerm(null);
              setInsurance(false);
              setErrors({});
              navigate("/");
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              message.error(t(error.response.data.error));
            } else {
              message.error("Error issuing loan");
            }
          });
      } catch (e) {
        console.error("Error sending transaction:", e);
      }
    } else {
      if (userData && userFriendlyAddress) {
        let comment;
        if (tokenType === "USDT") {
          comment = `UserId:${
            userData!.id
          };Rate:${rate};Term:${term};Insurance:${insurance};Margin:${marginLoan}`;
        } else {
          comment = `UserId:${
            userData!.id
          };Rate:${rate};Term:${term};Insurance:${insurance}`;
        }

        const walletResponse = await getPaymentWallet(userFriendlyAddress);

        if (walletResponse && walletResponse.walletAddress) {
          const myTransaction = await sendTransaction(
            comment,
            tokenType,
            wallet_address,
            walletResponse.walletAddress,
            amount
          );
          if (myTransaction) {
            const result = await tonConnectUI.sendTransaction(myTransaction);
            navigate("/");
          } else {
            console.error("Transaction could not be created.");
          }
        } else {
          console.error("Failed to fetch payment wallet from server.");
        }
      }
    }
  };

  if (!userData) {
    return;
  }

  return (
    <div style={{ padding: "20px", backgroundColor: "#fff" }}>
      <Title level={3}>{t("to issue a loan")}</Title>
      <Form layout="vertical">
        <Form.Item extra={t("Loan disbursement at the expense of bonus funds")}>
          <Switch
            checked={useBonus}
            onChange={() => {
              setUseBonus(!useBonus);
              setInsurance(true);
              setAmount(null);
              setRate(null);
            }}
          />
        </Form.Item>
        {messageText && (
          <Text style={{ marginTop: "16px", display: "block" }}>
            {messageText}
          </Text>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <Checkbox
            checked={insurance}
            disabled={useBonus}
            onChange={() => {
              setInsurance(!insurance);
              setMarginLoan(0);
            }}
          >
            {t("To insure a loan")}{" "}
            <Link href="https://webtransfer.com/wt_insurance" target="_blank">
              {t("Learn more...")}
            </Link>
          </Checkbox>
        </Form.Item>
        <Link
          href="https://webtransfer.com/risk-warning"
          target="_blank"
          style={{ fontSize: 12, textAlign: "center" }}
        >
          {t("Risk disclosure")}
        </Link>
        <Form.Item
          label={t("Amount")}
          extra={
            !useBonus && `Min. ${tokenType === "USDT" ? "10 USDT" : "1 TON"}`
          }
          validateStatus={errors.amount ? "error" : ""}
          help={errors.amount}
        >
          <InputNumber
            placeholder={t("Enter amount")}
            addonBefore={useBonus ? "$" : selectBefore}
            value={
              mode === "Basic" && useBonus ? userData!.bonus_balance : amount
            }
            onChange={(value) => setAmount(value)}
            style={{ width: "100%" }}
            disabled={mode === "Basic" && useBonus}
            min={useBonus ? 1 : tokenType === "USDT" ? 10 : 1}
            formatter={(value) => {
              if (value === undefined) return "";
              return `${value}`.replace(",", ".");
            }}
            parser={(value) => {
              if (value === undefined) return 0;
              return parseFloat(value.replace(",", "."));
            }}
          />
        </Form.Item>
        {amount &&
          ((amount > 200 && tokenType === "USDT") ||
            (amount > 50 && tokenType === "TON")) && (
            <Alert
              message={
                <>
                  {t("overnight.invest_massage")}{" "}
                <span style={{color: "#1677ff"}}
                  onClick={() =>
                    WebApp.openTelegramLink(
                      `https://t.me/webtransfercredit/203`
                    )
                  }
                >
                {t("Learn more...")}
                </span>
                </>
              }
              type="info"
              action={<BulbTwoTone style={{ fontSize: "16px" }} />}
              style={{ marginBottom: "10px" }}
            />
          )}
        <Form.Item
          label={t("Rate")}
          validateStatus={errors.rate ? "error" : ""}
          help={errors.rate}
          extra={
            mode === "Full" && (
              <div className="rates">
                {t("Popular rates")}:
                {insurance ? (
                  userData?.popular_rates.map((rate) => (
                    <Link key={rate} onClick={() => setRate(rate)}>
                      {rate}%
                    </Link>
                  ))
                ) : (
                  <Link
                    onClick={() =>
                      setRate(Number(userData.settings.max_rate_high_risk))
                    }
                  >
                    {userData.settings.max_rate_high_risk}%
                  </Link>
                )}
              </div>
            )
          }
        >
          <InputNumber
            placeholder="0.25"
            value={mode === "Basic" ? (useBonus ? 0.21 : rate) : rate}
            onChange={(value) => setRate(value)}
            style={{ width: "100%" }}
            decimalSeparator="."
            disabled={mode === "Basic" && useBonus}
            formatter={(value) => {
              if (value === undefined) return "";
              return `${value}`.replace(",", ".");
            }}
            parser={(value) => {
              if (value === undefined) return 0;
              return parseFloat(value.replace(",", "."));
            }}
          />
        </Form.Item>
        {!useBonus && (
          <Form.Item
            label={t("Term")}
            extra={
              <div>
                <div className="rates">
                  {t("Popular terms")}:
                  {popularTerms.map((term) => (
                    <Link key={term} onClick={() => setTerm(term)}>
                      {term}
                    </Link>
                  ))}
                </div>
                {insurance ? `Min. 3 day` : `Min. 15 day`}
              </div>
            }
            validateStatus={errors.term ? "error" : ""}
            help={errors.term}
          >
            <InputNumber
              placeholder={t("Enter term in days")}
              value={term}
              onChange={(value) => setTerm(value)}
              style={{ width: "100%" }}
              min={insurance ? 3 : 15}
              step={1}
              formatter={(value) => `${value}`}
              parser={(value) => parseInt(value!, 10)}
            />
          </Form.Item>
        )}
        {useBonus && (
          <Text type="secondary">
            {t("Bonuses can only be invested for 14 days")}
          </Text>
        )}
        {tokenType === "USDT" && !useBonus && (
          <>
            <Text type="secondary">
              {t("Margin Loans")} ({userData?.settings.margin_rate}%)
            </Text>
            <Radio.Group
              defaultValue="0"
              disabled={!insurance}
              buttonStyle="solid"
              style={{ margin: 15 }}
              onChange={(e) => setMarginLoan(e.target.value)}
              value={marginLoan}
            >
              <Radio.Button value={0}>0%</Radio.Button>
              <Radio.Button value={100}>100%</Radio.Button>
              <Radio.Button value={200}>200%</Radio.Button>
              <Radio.Button value={300}>300%</Radio.Button>
            </Radio.Group>
            <Link
              href="https://webtransfer.com/margin-loans"
              target="_blank"
              style={{ marginBottom: 20 }}
            >
              {t("Learn more...")}
            </Link>
          </>
        )}

        <Divider />
        <MainButton
          text={t("to issue a loan")}
          onClick={handleSubmit}
          disabled={disabled}
        />
      </Form>
    </div>
  );
};

export default LoanIssuance;
