import {FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Spin } from "antd";
import Post from "./Post";
import CommentsSection from "./Comments";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { PostType } from "../types";


const SinglePost: FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<PostType | null>(null);
  const [commentCount, setCommentCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { userData } = useSelector((state: RootState) => state.user);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchPost = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/account/get-single-post.php",
          {
            post_id: postId,
          }
        );
        setPost(response.data.post);
        setCommentCount(response.data.post.comments_count);
        const commentsResponse = await axios.post(
          "/api/account/get_post_comments.php",
          {
            post_id: postId,
          }
        );
        setComments(commentsResponse.data.comments);
      } catch (error) {
        console.error("Failed to fetch post:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  const handleAddComment = async (count: number) => {
    setCommentCount(prevCount => prevCount + count);
  }
  if (loading) {
    return <Spin size="large" />;
  }

  if (!post) {
    return <p>Post not found</p>;
  }


  return (
    <>
    <Post
      key={post.id}
      first_name={post.first_name}
      last_name={post.last_name}
      avatarUrl={post.avatar_url}
      content={post.content}
      imageUrl={post.image_url}
      likes={post.likes}
      comments_count={commentCount}
      postId={post.id}
      postTime={post.created_at}
      user_id={post.user_id}
      userId={userData!.id}
      poll={post.poll}
      liked_by_user={post.liked_by_user}
      view_count={post.view_count}
    />
    <CommentsSection comments={comments} postId={post.id} handleAddComment={handleAddComment} />
    </>
  );
};

export default SinglePost;
