import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "antd";

const { Paragraph, Title } = Typography;

const Risks: FC = () => {
  const { t } = useTranslation();


  return (
    <>
      <Title level={3}>{t("risks")}</Title>
      <Paragraph>{t("risks text")}</Paragraph>
    </>
  );
};

export default Risks;
