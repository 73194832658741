import Settings from '../pages/Settings';
import Language from '../pages/Language';
import FAQ from '../pages/FAQ';
import About from '../pages/About';
import FollowingFeed from '../pages/FollowingFeed';
import Risks from '../pages/Risks';
import Statistics from '../pages/Statistics';
import LoanIssuance from '../pages/LoanIssuance';
import LoanOvernight from '../pages/LoanOvernight';
import OvernightPartners from '../pages/OvernightPartners';
import LoanInsure from '../pages/LoanInsure';
import LoanExchange from '../pages/LoanExchange';
import LoanPage from '../pages/LoanPage';
import Partners from '../pages/Partners';
import PartnersStatistics from '../pages/PartnersStatistics';
import Invest from '../pages/Invest';
import InvestPayment from '../pages/InvestPayment';
import Marketing from '../pages/Marketing';
import WebTokens from '../pages/WebTokens';
import Tasks from '../pages/Tasks';
import WithdrawalPage from '../pages/Withdrawal';
import Notifications from '../pages/Notifications';
import Home from '../pages/Home';
import Premium from '../pages/Premium';
import InvestPartnerBalance from '../pages/InvestPartnerBalance';
import BankGame from '../pages/Game/BankGame';
import PartnerDetails from '../components/PartnerDetails';
import SinglePost from '../components/SinglePost';
import NotificationSettings from '../components/NotificationSettings';
import TransactionHistory from "../components/history";
import Loans from "../components/loans";

const routes = [
  { path: '/settings', component: Settings },
  { path: '/settings/language', component: Language },
  { path: '/settings/notifications', component: NotificationSettings },
  { path: '/settings/faq', component: FAQ },
  { path: '/settings/about', component: About },
  { path: '/settings/risks', component: Risks },
  { path: '/notifications', component: Notifications },
  { path: '/statistics', component: Statistics },
  { path: '/loan-issuance', component: LoanIssuance },
  { path: '/loan-overnight', component: LoanOvernight },
  { path: '/loan-overnight/partners', component: OvernightPartners },
  { path: '/loan-insure', component: LoanInsure },
  { path: '/p2p-loans', component: LoanExchange },
  { path: '/loan/:loanId', component: LoanPage },
  { path: '/partners', component: Partners },
  { path: '/partners/statistics', component: PartnersStatistics },
  { path: '/partners/:userId', component: PartnerDetails },
  { path: '/feed', component: FollowingFeed },
  { path: '/post/:postId', component: SinglePost },
  { path: '/invest', component: Invest },
  { path: '/invest/payment', component: InvestPayment },
  { path: '/invest/marketing', component: Marketing },
  { path: '/web-tokens', component: WebTokens },
  { path: '/web-tokens/tasks', component: Tasks },
  { path: '/withdraw', component: WithdrawalPage },
  { path: '/bank-game', component: BankGame },
  { path: '/premium', component: Premium },
  { path: '/invest-partners-balance', component: InvestPartnerBalance },
  { path: '/history', component: TransactionHistory },
  { path: '/loans', component: Loans },
  { path: '/', component: Home },
  { path: '*', component: Home },
];

export default routes;
