import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchUserLoans } from "../helpers/api";
import { getStatusColor } from "../helpers/functions";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import {
  List,
  Typography,
  Spin,
  Select,
  Tag,
  Pagination,
  Row,
  Col,
} from "antd";
import { Loan } from "../types";

const { Text } = Typography;

const Loans: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userData } = useSelector((state: RootState) => state.user);
  const [loans, setLoans] = useState<Loan[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const itemsPerPage = 10;

  const loadLoans = async (currentPage: number, currentStatus?: string) => {
    setLoading(true);
    try {
      const data = await fetchUserLoans(
        currentPage,
        itemsPerPage,
        currentStatus
      );
      setLoans(data.loans);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error("Ошибка");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const currentStatus = searchParams.get("status") || undefined;

    setPage(currentPage);
    setStatus(currentStatus);
  }, [searchParams]);

  useEffect(() => {
    if (userData) {
      loadLoans(page, status);
    }
  }, [userData, page, status]);

  const handleStatusChange = (value: string) => {
    setStatus(value);
    fetchUserLoans(page, itemsPerPage, value);
    setPage(1);
    setSearchParams({ page: "1", status: value });
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setSearchParams({ page: newPage.toString(), status: status || "" });
  };


  return (
    <div>
      <Typography.Title level={4}>{t("Loans")}</Typography.Title>
      <Select
        placeholder={t("Status")}
        onChange={handleStatusChange}
        style={{ width: 200, marginBottom: 20 }}
        loading={loading}
        options={[
          { value: "active", label: t("loan_status.active") },
          { value: "completed", label: t("loan_status.completed") },
          { value: "pending", label: t("loan_status.pending") },
          { value: "for_sale", label: t("loan_status.for_sale") },
          { value: "sold", label: t("loan_status.sold") },
          { value: "cancelled", label: t("loan_status.cancelled") },
        ]}
      ></Select>
      {loading ? (
          <div className="loader-container"><Spin size="large" /></div>
      ) : (
        <>
          <List
            itemLayout="horizontal"
            dataSource={loans}
            renderItem={(loan) => (
              <List.Item
              onClick={() => navigate(`/loan/${loan.loan_id}?page=${page}&status=${status || ""}`)}
                style={{ cursor: "pointer", justifyContent: "space-between" }}
              >
                <List.Item.Meta
                  title={
                    <div className="history-item">
                      <Text>
                        {loan.used_bonus ? (
                          `$ ${loan.amount}`
                        ) : (
                          <>
                            {loan.currency === "USDT" ? (
                              <img
                                src="./assets/UsdtLogo.webp"
                                alt="USDT Logo"
                                width={12}
                                style={{ marginRight: 5 }}
                              />
                            ) : (
                              <img
                                src="./assets/tonIcon.png"
                                alt="Logo"
                                width={12}
                                style={{ marginRight: 5 }}
                              />
                            )}
                            {loan.amount}
                          </>
                        )}
                      </Text>
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, fontWeight: "normal" }}
                      >
                        {t("Rate")}: {loan.rate}% / {t("Profit")}:{" "}
                        {(
                          loan.profit - loan.insurance_pool_contribution
                        ).toFixed(2)}
                      </Text>
                    </div>
                  }
                />
                <Tag color={getStatusColor(loan.status)}>
                  {t(`loan_status.${loan.status}`)}
                </Tag>
              </List.Item>
            )}
            locale={{ emptyText: t("You don't have any loans yet") }}
          />

          <Row justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Pagination
                current={page}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </>
      )}

      {/*
            {selectedLoan?.status === "completed" && (
              <Rate
                className="loan-stars"
                value={rating}
                onChange={(value) => {
                  setRating(value);
                  message.success(t("Thanks"));
                }}
              />
            )}
          </>
        )}
          */}
    </div>
  );
};

export default Loans;
