import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Statistic,
  Spin,
  Button,
  Modal,
} from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { PartnerStatisticsResponse } from "../types";

const { Title, Text } = Typography;

const PartnersStatistics: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] =
    useState<PartnerStatisticsResponse | null>(null);


  useEffect(() => {
    setIsLoading(true);
    axios
      .post("/api/partners/get-partners-statistics.php")
      .then((response) => {
        setStatistics(response.data);
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userData]);

  const handleNotifyChildren = () => {
    axios
      .post("/api/partners/notify-children.php")
      .then((response) => {
        if (response.data.success) {
          Modal.success({
            content: t("Notification sent successfully"),
          });
        } else {
          Modal.error({
            content: t(response.data.error),
          });
        }
      })
      .catch((error) => {
        console.error("Error sending notification:", error);
      });
  };

  if (isLoading || !statistics) {
    return (
      <div className="loader-container">
        <Spin size="large" />
      </div>
    );
  }

  const levels = [1, 2, 3, 4, 5];
  const totalActiveLoans = levels.reduce((total, level) => {
    return total + Number(statistics.levels[level]?.total_active_loans || 0);
  }, 0);

  return (
    <div style={{ padding: "20px" }}>
      <Title level={3}>{t("Partners statistics")}</Title>
      <Card title={t("The amount of active loans")} style={{ marginTop: 20 }}>
        <Row gutter={16}>
          {levels.map(
            (level) =>
              statistics.levels[level]?.total_active_loans != null && (
                <Col span={24} style={{ marginTop: 10 }} key={level}>
                  <Statistic
                    title={`${level} ${t("Level")}`}
                    value={
                      Number(
                        statistics.levels[level]?.total_active_loans
                      ).toFixed(2) || 0
                    }
                    prefix="$"
                  />
                </Col>
              )
          )}
          <Col span={24} style={{ marginTop: 10 }}>
            <Statistic title={t("Total")} value={totalActiveLoans} prefix="$" />
          </Col>
        </Row>
      </Card>
      <Card title={t("Notifications")} style={{ marginTop: 20 }}>
        <div className="history-item-price">
        {userData && userData.premium_status === 1 ? (
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={handleNotifyChildren}
          >
            {t("Notify Junior Partners")}
          </Button>
        ) : (
          <Button
            type="primary"
            style={{ marginTop: 20 }}
            onClick={() => navigate("/premium")}
          >
            {t("Available with a premium subscription")}
          </Button>
        )}
        <Text type="secondary" style={{ fontSize: 12, marginTop: 10}}>
          {t("Junior_notifications_caption")}
        </Text>
        </div>
      </Card>
    </div>
  );
};

export default PartnersStatistics;
