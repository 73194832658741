import { FC, useState } from "react";
import { MainButton } from "@twa-dev/sdk/react";
import { useTranslation } from "react-i18next";
import { sendTransaction } from "../helpers/transactionService";
import { useSelector } from "react-redux";
import { useTonConnectUI } from "@tonconnect/ui-react";
import { RootState } from "../services/store";
import NonPremiumContent from "../components/nonPremiumContent";
import PremiumContent from "../components/PremiumContent";

const Premium: FC = () => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [tonConnectUI] = useTonConnectUI();
  const { userData } = useSelector((state: RootState) => state.user);


  const handleSubmit = async () => {
    setDisabled(true);
    const comment = `UserId:${userData!.id};`;

    const myTransaction = await sendTransaction(
      comment,
      "TON",
      null,
      "UQCOB-m8imN3d6BQ6y2yrLota6epBkrD4Y05hgmsXEuS8bzT",
      1
    );
    if (myTransaction) {
      const result = await tonConnectUI.sendTransaction(myTransaction);
      console.log(result);
    } else {
      console.error("Transaction could not be created.");
    }
  };

  return (
    <div>
      {userData && userData.premium_status === 1 ? (
        <PremiumContent />
      ) : (
        <>
          <NonPremiumContent />
          <MainButton
            text={t("Buy Premium Status")}
            onClick={handleSubmit}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};

export default Premium;
