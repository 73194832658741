import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Button, Select, message } from "antd";
import { getAvailableAmountToInvest, InvestPartners } from "../helpers/api";
import { updatePartnerBalance } from "../services/slices/userSlice";
import { RootState, AppDispatch } from "../services/store";

const { Title } = Typography;

const InvestPartnerBalance: FC = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const [amount, setAmount] = useState<number>(0);
  const [term, setTerm] = useState<number>(3);
  const [disabled, setDisabled] = useState<boolean>(false);


  const fetchAvailableAmount = async () => {
    try {
      const data = await getAvailableAmountToInvest();
      setAmount(data.available_withdrawal_amount);
    } catch (error) {
      console.error("Error fetching available  amount", error);
    }
  };

  useEffect(() => {
    fetchAvailableAmount();
  }, []);

  const handleButton = () => {
    setDisabled(true);
    const loanData = {
      amount,
      term,
    };
    InvestPartners(loanData)
      .then((response) => {
        setDisabled(false);
        if (response.error) {
          message.error(t(response.error));
        } else {
          message.success(t("Loan issued successfully"));
          setAmount(0);
          dispatch(updatePartnerBalance(amount));
        }
      })
      .catch((error) => {
        setDisabled(false);
        message.error(t("Error issuing loan"));
      });
  };

  return (
    <>
      <Title level={5}>{t("Invest Partner Balance In Margin Loans")}</Title>
      <div className="balance_item">
        <span>{t("Rate")}</span>
        <span className="balance_item_price">
          {userData?.settings.margin_rate}%
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Available amount")}</span>
        <span className="balance_item_price">${amount}</span>
      </div>
      <Select
        defaultValue={3}
        options={[
          { value: 3, label: <span>3 days</span> },
          { value: 7, label: <span>7 days</span> },
        ]}
        onChange={(value) => setTerm(Number(value))}
      />
      <Button
        type="primary"
        onClick={handleButton}
        style={{ margin: "20px" }}
        disabled={disabled}
      >
        {t("Invest")} ${amount}
      </Button>
    </>
  );
};

export default InvestPartnerBalance;
