import TonWeb from "tonweb";
import { Transaction } from '../types';

export const getRateColor = (rate: number) => {
  if (rate <= 0.2) {
    return "green";
  } else if (rate <= 0.3) {
    return "yellow";
  } else {
    return "red";
  }
};

export const separateTonAddress = (address: string) => `${address.slice(0, 4)}...${address.slice(-4)}`;

export const calculateUsdtAmount = (usdCents: number) => BigInt(usdCents * 10000);

export const calculateUsdFromUsdt = (usdtAmount: bigint) => Math.round((Number(usdtAmount) / 1000000) * 100) / 100;

export const isUUID = (uuid: string): boolean => uuid.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$') !== null;


export const determineTransactionDetails = (transaction: Transaction) => {
  let sign = "";
  let color = "";
  let displayAmount = transaction.amount.toString();

  // Определяем знак и цвет для типов транзакций
  switch (transaction.transaction_type) {
    case 'deposit':
    case 'loan_repayment':
    case 'referral_bonus':
    case 'correction':
    case 'refund':
      sign = "+";
      color = "green";
      break;
    case 'withdrawal':
    case 'loan_issue':
    case 'bonus_deduction':
    case 'insurance_pool':
      sign = "-";
      color = "red";
      break;
    default:
      color = "black";
      break;
  }

  // Добавляем знак к сумме
  displayAmount = `${sign}${transaction.amount}`;

  return { displayAmount, color };
};


export const isValidTonAddress = (address: string): boolean => {
  try {
    const tonAddress = new TonWeb.utils.Address(address);
    return true;
  } catch (error) {
    return false;
  }
};


// Функция для форматирования числа
export const formatViewCount = (count: number): string => {
  if (count >= 1000000) {
    return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (count >= 1000) {
    return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return count.toString();
};


export const getStatusColor = (status: string) => {
  switch (status) {
    case "active":
      return "green";
    case "pending":
      return "orange";
    case "cancelled":
      return "red";
    case "completed":
      return "blue";
    case "for_sale":
      return "cyan";
    case "sold":
      return "geekblue";
    default:
      return "gray";
  }
};


export const shortenHash = (hash: string) => {
  const firstChars = hash.substring(0, 6);
  const lastChars = hash.substring(hash.length - 4);
  return `${firstChars}...${lastChars}`;
};