import { FC, useEffect, useState } from "react";
import { List, Typography, Pagination, Row, Col, Tag, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchPartnersTransactions } from "../helpers/api";
import { PartnerTransaction } from "../types";
import { shortenHash } from "../helpers/functions";

const { Title, Text } = Typography;

const OvernightPartners: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState<PartnerTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const loadTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetchPartnersTransactions(page, itemsPerPage);
        setTransactions(response.data);
        setTotalPages(response.pagination.total_pages);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTransactions();
  }, [page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Title level={5}>{t("Accrual history")}</Title>
      {loading ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <List
            itemLayout="horizontal"
            dataSource={transactions}
            renderItem={(transaction) => (
              <List.Item style={{ cursor: "pointer" }}>
                <List.Item.Meta
                  title={
                    <div className="history-item">
                      <Text
                        onClick={() =>
                          navigate(`/partners/${transaction.referred_user_id}`)
                        }
                      >
                        {transaction.referred_user_name}{" "}
                        {transaction.referred_last_name}
                      </Text>
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, fontWeight: "normal" }}
                      >
                        {`${transaction.amount} TON`}
                      </Text>
                      {!!transaction.is_paid && transaction.transaction_hash && (
                        <Text
                          type="secondary"
                          style={{ fontSize: 12, fontWeight: "normal" }}
                        >
                          Hash:{" "}
                          <a
                            href={`https://tonviewer.com/transaction/${transaction.transaction_hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                          >
                            {shortenHash(transaction.transaction_hash)}
                          </a>
                        </Text>
                      )}
                    </div>
                  }
                />
                <Tag color={transaction.is_paid ? "green" : "orange"}>
                  {transaction.is_paid ? t("paid") : t("not_paid")}
                </Tag>
              </List.Item>
            )}
          />
          <Row justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Pagination
                current={page}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default OvernightPartners;
