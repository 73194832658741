import React from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/dashboard";
import Balances from "../components/balances";
import ModeSwitch from "../components/ModeSwitch";
import { useSelector } from "react-redux";
import {
  SettingTwoTone,
  PieChartTwoTone,
  BellTwoTone,
  QuestionCircleTwoTone
} from "@ant-design/icons";
import {  Badge } from "antd";
import { RootState } from "../services/store";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.app.mode);


  return (
    userData && (
      <>
        <div className="header">
          <ModeSwitch />
          <div>
            {mode === "Full" ? (
              <PieChartTwoTone
                onClick={() => navigate("/statistics")}
                style={{ fontSize: "24px", cursor: "pointer", marginRight: 10 }}
              />
            ) : (
              <QuestionCircleTwoTone
                onClick={() => navigate("/settings/faq")}
                style={{ fontSize: "24px", cursor: "pointer", marginRight: 10 }}
              />
            )}
            <Badge count={!isLoading && userData.unread_notifications}>
              <BellTwoTone
                onClick={() => navigate("/notifications")}
                style={{
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </Badge>
            <SettingTwoTone
              onClick={() => navigate("/settings")}
              style={{ fontSize: "24px", cursor: "pointer", marginLeft: 10 }}
            />
          </div>
        </div>
        <Balances />
        <Dashboard />
      </>
    )
  );
};

export default Home;
