import { FC } from "react";
import CertificatePaymentForm from "../components/CertificatePaymentForm";


const InvestPayment: FC = () => {

  return <CertificatePaymentForm />;
};

export default InvestPayment;
