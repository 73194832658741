import { FC, useEffect, useState } from "react";
import { List, Typography, Pagination, Row, Col, Tag, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { Withdrawal } from "../types";
import { fetchWithdrawals } from "../helpers/api";
import { shortenHash } from "../helpers/functions";

const { Title, Text } = Typography;

const WithdrawalHistory: FC<{ shouldRefresh: boolean }> = ({
  shouldRefresh,
}) => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.user);
  const [withdrawals, setWithdrawals] = useState<Withdrawal[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalSumm, setTotalSumm] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    const getWithdrawals = async () => {
      setLoading(true);
      try {
        const data = await fetchWithdrawals(page, itemsPerPage);
        setWithdrawals(data.withdrawals);
        setTotalPages(data.totalPages);
        setTotalSumm(data.totalCompleted);
      } catch (error) {
        console.error("Error fetching withdrawals:", error);
      } finally {
        setLoading(false);
      }
    };

    getWithdrawals();
  }, [userData, page, shouldRefresh]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Title level={5}>{t("history")}</Title>
      {loading ? (
        <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <Text>
            {t("Total")}: ${totalSumm}
          </Text>
          <List
            dataSource={withdrawals}
            renderItem={(withdrawal) => (
              <List.Item key={withdrawal.withdrawal_id}>
                <List.Item.Meta
                  title={
                    <div>
                      <img
                        src="./assets/tonIcon.png"
                        alt="Logo"
                        width={12}
                        style={{ marginRight: 5 }}
                      />
                      {withdrawal.amount}
                    </div>
                  }
                  description={
                    <>
                      <Text
                        type="secondary"
                        style={{ fontSize: 12, fontWeight: "normal" }}
                      >
                        {new Date(withdrawal.updated_at).toLocaleString()}
                      </Text><br/>
                      {withdrawal.hash && (
                        <Text type="secondary">
                          Hash:{" "}
                          <a
                            href={`https://tonviewer.com/transaction/${withdrawal.hash}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                          >
                            {shortenHash(withdrawal.hash)}
                          </a>
                        </Text>
                      )}
                    </>
                  }
                />
                {withdrawal.status === "completed" ? (
                  <Tag color="green">Completed</Tag>
                ) : withdrawal.status === "pending" ? (
                  <Tag color="orange">Pending</Tag>
                ) : (
                  <Tag color="red">Failed</Tag>
                )}
              </List.Item>
            )}
            style={{
              background: "#fff",
              padding: "16px",
              marginTop: 20,
            }}
          />
          <Row justify="center" style={{ marginTop: 20 }}>
            <Col>
              <Pagination
                current={page}
                total={totalPages * itemsPerPage}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default WithdrawalHistory;
