import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../services/store";
import { Tag } from 'antd';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.app.mode);

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const cardItems = [
    ...(mode === "Full"
      ? [
          {
            path: `/partners/${userData?.id}`,
            title: t("My profile"),
          },
          {
            path: "/feed",
            title: t("Feed"),
          },
        ]
      : []),
    {
      path: "/loan-issuance",
      title: t("to issue a loan"),
    },
    {
      path: "/loan-overnight",
      title: t("Overnight Loan"),
      tag: <Tag color="red">NEW</Tag>,
    },
    {
      path: "/loan-insure",
      title:t("Take out a loan"),
    },
    // {
    //   path: "/p2p-loans",
    //   title: t("P2P"),
    // },
    {
      path: "/loans",
      title: t("Loans"),
    },
    ...(mode === "Full"
      ? [
          {
            path: "/invest",
            title: t("Invest in Loans Wholesale"),
          },
          {
            path: "/premium",
            title: t("Premium"),
          },
        ]
      : []),
      {
        path: "/history",
        title: t("history"),
      },
    {
      path: "/partners",
      title: t("partners network"),
    },
    ...(mode === "Full"
      ? [
          {
            path: "/bank-game",
            title: t("Farming.Title"),
          },
          {
            path: "/web-tokens",
            title: t("web credits"),
          },
        ]
      : []),

    {
      path: "/withdraw",
      title: t("Withdrawal"),
    },
  ];

  return (
    <div className="dashboard">
      {cardItems.map((item) => (
        <div
          className="tabs_panel"
          onClick={() => navigateTo(item.path)}
          key={item.title}
        >
            {item.title}
            {item.tag && <Tag color="#ff453a">NEW</Tag>}
        </div>
      ))}
    </div>
  );
};

export default Dashboard;
