import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Spin, Typography } from "antd";
import TonBalance from "../components/TonBalance";
import { useTonWallet } from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";
import { fetchTonData } from "../services/slices/tonSlice";
import { RootState, AppDispatch } from "../services/store";

const { Text } = Typography;

const Balances: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const wallet = useTonWallet();
  const navigate = useNavigate();
  const { userData, isLoading } = useSelector((state: RootState) => state.user);
  const mode = useSelector((state: RootState) => state.app.mode);

  useEffect(() => {
    dispatch(fetchTonData(null));
  }, [dispatch]);

  return (
    <>
      <Text strong>{t("real balance")}</Text>
      {wallet && <TonBalance />}
      <div
        className="balance_item"
        onClick={() =>
          navigate("/loan-issuance", { state: { useBonus: true } })
        }
      >
        <span>{t("bonus balance")}</span>
        <span className="balance_item_price">
          {isLoading ? (
            <Spin size="large" />
          ) : (
            `$${userData?.bonus_balance ?? 0}`
          )}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("balance")}</span>
        <span className="balance_item_price">
          {isLoading ? (
            <Spin size="large" />
          ) : (
            `$${userData?.real_balance ?? 0}`
          )}
        </span>
      </div>
      <div className="balance_item" onClick={() => navigate("/partners")}>
        <span>{t("Partner Balance")}</span>
        <span className="balance_item_price">
          {isLoading ? (
            <Spin size="large" />
          ) : (
            `$${userData?.partner_balance ?? 0}`
          )}
        </span>
      </div>

      {mode === "Full" && (
        <div className="balance_item" onClick={() => navigate("/web-tokens")}>
          <div className="balance_item_name">
            <img src="./assets/LogoW.png" alt="Logo" width={30} />
            <span>$WEB</span>
          </div>
          <span className="balance_item_price">
            {isLoading ? <Spin size="large" /> : `${userData?.web_tokens ?? 0}`}
          </span>
        </div>
      )}
    </>
  );
};

export default Balances;
