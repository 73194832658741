import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { List, Avatar, Typography } from "antd";
import { useDispatch } from "react-redux";
import { updateUnreadNotifications } from "../services/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../services/store";
import { getNotifications, notificationsAsRead } from "../helpers/api";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import { Notification } from "../types";

const { Paragraph } = Typography;

const Notifications: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {


    const fetchNotifications = async () => {
      try {
        const notificationsData = await getNotifications();
        setNotifications(notificationsData);

        await notificationsAsRead();
        dispatch(updateUnreadNotifications(0));
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();

  }, [dispatch]);


  return (
    <div>
      <h4>{t("notifications")}</h4>
      <List
        itemLayout="horizontal"
        dataSource={notifications}
        renderItem={(notification, index) => (
          <div
            className="notification_contain"
            onClick={() =>
              notification.post ? navigate(`/post/${notification.post.id}`) : navigate(`/partners/${notification.follower?.id}`)
            }
          >
            <div className="notification_list_item">
              <Avatar
                icon={<UserOutlined />}
                size={40}
                src={notification.avatar_url}
              />
              <div className="comment_texts">
                  <span className="comment_author">
                    {t(`notification_type.${notification.type}`)}
                  </span>
                <Paragraph
                  type="secondary"
                  style={{ marginBottom: 0 }}
                  ellipsis={{ rows: 1, expandable: true, symbol: "" }}
                >
                  {notification.content}
                </Paragraph>
                <span className="comment_date">
                  {moment.utc(notification.created_at).fromNow()}
                </span>
              </div>
            </div>
              <RightOutlined />
          </div>
        )}
      />
    </div>
  );
};

export default Notifications;
