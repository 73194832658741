import React, { useEffect } from "react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useTonAddress } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../services/store";
import { fetchTonData } from "../services/slices/tonSlice";
import { fetchUSDTData } from "../services/slices/usdtSlice";

const TonBalance: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userFriendlyAddress = useTonAddress();
  const navigate = useNavigate();

  const { balance, isLoading: tonLoading } = useSelector(
    (state: RootState) => state.ton
  );
  const { balance: usdtBalance, isLoading: usdtLoading } = useSelector(
    (state: RootState) => state.usdt
  );

  useEffect(() => {
    if (userFriendlyAddress) {
      dispatch(fetchTonData(userFriendlyAddress));
      dispatch(fetchUSDTData(userFriendlyAddress));
    }
  }, [userFriendlyAddress, dispatch]);

  return (
    <>
      <div
        className="balance_item"
        onClick={() =>
          navigate("/loan-issuance", { state: { tokenType: true } })
        }
      >
        <div className="balance_item_name">
          <img
            src="./assets/tonIcon.png"
            alt="TON Logo"
            width={16}
            height={16}
          />
          <span>TON</span>
        </div>
        <span className="balance_item_price">
          {tonLoading ? <Spin size="small" /> : `${balance ?? 0}`}
        </span>
      </div>

      <div className="balance_item" onClick={() => navigate("/loan-issuance")}>
        <div className="balance_item_name">
          <img
            src="./assets/UsdtLogo.webp"
            alt="USDT Logo"
            width={16}
            height={16}
          />
          <span>USDT</span>
        </div>
        <span className="balance_item_price">
          {usdtLoading ? <Spin size="small" /> : `${usdtBalance ?? 0}`}
        </span>
      </div>
    </>
  );
};

export default TonBalance;
