import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Typography,
  Spin,
  Row,
  Button,
  InputNumber,
  message,
  Alert,
  Tag,
  Modal,
} from "antd";
import {
  fetchLoanDetails,
  changeLoanRate,
  fetchSellLoans,
  fetchRemoveSellLoans,
} from "../helpers/api";
import { getStatusColor } from "../helpers/functions";
import LoanCertificate from "../components/loanCertificate";
import { LoanDetails } from "../types";
import LoanProgress from "../components/LoanProgress";

const LoanPage: FC = () => {
  const { t } = useTranslation();
  const { loanId } = useParams<{ loanId: string }>();
  const [loanDetails, setLoanDetails] = useState<LoanDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [newRate, setNewRate] = useState<number | null>(null);
  const [rate, setRate] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);


  const loadLoanDetails = async () => {
    try {
      setLoading(true);
      const loanIdNumber = Number(loanId);
      if (isNaN(loanIdNumber)) {
        throw new Error(t("Invalid loan ID"));
      }
      const data = await fetchLoanDetails(loanIdNumber);
      setLoanDetails(data);
      setRate(data.rate);
    } catch (err) {
      setError(t("Failed to fetch loan details"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLoanDetails();
  }, []);

  const handleRateChange = () => {
    if (!loanDetails || newRate === null) return;
    const loanIdNumber = Number(loanId);
    changeLoanRate(loanIdNumber, newRate)
      .then((response) => {
        if (response.success) {
          setRate(newRate);
          message.success(t("Success"));
        } else {
          message.error(t("Error"));
          console.error("Error changing loan rate:", response.error);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const infoFormatterBonus = (value: number) => {
    if (value === 1) {
      return <Tag color="green">{t('bonus')}</Tag>;
    } else if (value === 2) {
      return <Tag color="cyan">{t('partner')}</Tag>;
    } else {
      return <Tag color="blue">{t('personal')}</Tag>;
    }
  };

  const handleSellLoan = async () => {
    setConfirmLoading(true);
    const loanIdNumber = Number(loanId);
    await fetchSellLoans(loanIdNumber).then((response) => {
      if (response.success) {
        message.success(t("Success"));
        setConfirmLoading(false);
      } else {
        message.error(t("Error"));
        setConfirmLoading(false);

        console.error("Error changing loan rate:", response.error);
      }
    });
    setConfirmLoading(false);
    await loadLoanDetails();
    setOpenModal(false);
  };

  const handleRemoveSellLoan = async () => {
    const loanIdNumber = Number(loanId);
    await fetchRemoveSellLoans(loanIdNumber);
    await loadLoanDetails();
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  if (!loanDetails) {
    return <Alert message={t("Loan not found")} type="warning" />;
  }

  return (
    <div>
      <Typography.Title level={4}>
        {t("Loan Details")} №{loanId}
      </Typography.Title>
      <LoanProgress created_at={loanDetails.created_at} expires_at={loanDetails.expires_at} />
      <div className="balance_item">
        <span>{t("Status")}</span>
        <span className="balance_item_price">
          {
            <Tag color={getStatusColor(loanDetails.status)}>
              {t(`loan_status.${loanDetails.status}`)}
            </Tag>
          }
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Type")}</span>
        <span className="balance_item_price">
          {infoFormatterBonus(loanDetails.used_bonus)}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Amount")}</span>
        <span className="balance_item_price">{loanDetails.amount}</span>
      </div>
      {loanDetails.margin > 0 && (
        <div className="balance_item">
          <span>{t("The amount without a margin loan")}</span>
          <span className="balance_item_price">{loanDetails.fix_amount}</span>
        </div>
      )}
      {loanDetails.currency !== "" && (
        <div className="balance_item">
          <span>{t("Currency")}</span>
          <span className="balance_item_price">{loanDetails.currency}</span>
        </div>
      )}
      <div className="balance_item">
        <span>{t("Term")}</span>
        <span className="balance_item_price">
          {loanDetails.term} {t("days")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Rate")}</span>
        <span className="balance_item_price">{rate}%</span>
      </div>
      <div className="balance_item">
        <span>{t("Insurance")}</span>
        <span className="balance_item_price">
          {loanDetails.insurance ? t("Yes") : t("No")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Profit")}</span>
        <span className="balance_item_price">{loanDetails.profit}</span>
      </div>
      <div className="balance_item">
        <span>{t("Created")}</span>
        <span className="balance_item_price">
          {moment.utc(loanDetails.created_at).format("D MMM YYYY")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("Expires")}</span>
        <span className="balance_item_price">
          {moment.utc(loanDetails.expires_at).format("D MMM YYYY")}
        </span>
      </div>
      <div className="balance_item">
        <span>{t("To be refunded")}</span>
        <span className="balance_item_price">
          {loanDetails.total_repayment}
        </span>
      </div>
      {loanDetails.margin > 0 && (
        <Alert
          message={`${t("A margin loan has been used")} ${loanDetails.margin}%`}
          type="success"
          showIcon
        />
      )}
      {(loanDetails.status === "active" ||
        loanDetails.status === "completed" ||
        loanDetails.status === "for_sale") && (
        <LoanCertificate
          loanNumber={loanDetails.loan_id.toString()}
          loanAmount={loanDetails.amount}
          loanEndDate={loanDetails.expires_at.substring(0, 10)}
          loanDate={loanDetails.created_at.substring(0, 10)}
        />
      )}
      {loanDetails?.pledged && (
        <Alert
          message={t("This certificate is pledged")}
          type="warning"
          showIcon
        />
      )}
      {loanDetails.status === "pending" && (
        <>
          <Row justify="space-evenly" style={{ margin: 20 }} align="middle">
            <Typography.Text>{t("New rate")}:</Typography.Text>
            <InputNumber
              min={0}
              max={100}
              value={newRate}
              onChange={setNewRate}
              decimalSeparator="."
              formatter={(value) => {
                if (value === undefined) return "";
                return `${value}`.replace(",", ".");
              }}
              parser={(value) => {
                if (value === undefined) return 0;
                return parseFloat(value.replace(",", "."));
              }}
            />
          </Row>
          <Button key="changeRate" type="primary" onClick={handleRateChange}>
            {t("Change Rate")}
          </Button>
        </>
      )}
      {loanDetails.status === "active" && loanDetails.used_bonus === 0 && !loanDetails.pledged &&  (
        <Button
          key="sellLoan"
          color="danger"
          variant="outlined"
          onClick={showModal}
          className="mt-3"
        >
          {t("Sell Certificate")}
        </Button>
      )}
      {loanDetails.status === "for_sale" && (
        <Button
          key="sellLoan"
          color="danger"
          variant="outlined"
          onClick={handleRemoveSellLoan}
          className="mt-3"
        >
          {t("Remove the certificate from sale")}
        </Button>
      )}
      <Modal
        title={t("Change Rate")}
        open={openModal}
        onOk={handleSellLoan}
        okText={t("Confirm")}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        cancelText={t("Cancel")}
      >
        <p>{t("sell_loan_modal_massage")}</p>
      </Modal>
    </div>
  );
};

export default LoanPage;
