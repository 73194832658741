import { useEffect, ReactNode } from "react";
import { TonConnectButton } from "@tonconnect/ui-react";
import { useLocation, useNavigate } from "react-router-dom";


interface PageProps {
  children: ReactNode;
}

const Page: React.FC<PageProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className="connect">
      <img src="https://webtransfer.app/assets/logo.png" alt="Logo" width={150} onClick={() => navigate('/')}/>
        <TonConnectButton />
      </div>
      {children}
    </>
  );
};


export default Page;
