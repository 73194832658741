import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import OtherUserActions from "./OtherUserActions";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Card, Row, Col, Tag, Typography, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Feed from "./Feed";
import AvatarUpload from "./AvatarUpload";
import { Partner } from "../types";
import { UploadOutlined } from "@ant-design/icons";
import { updateBonusBalance } from "../services/slices/userSlice";
import { RootState, AppDispatch } from "../services/store";
import { formatViewCount } from "../helpers/functions";

const { Link } = Typography;

const PartnerDetails: FC = () => {
  const { t } = useTranslation();
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.user);
  const [partner, setPartner] = useState<Partner | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [premium, setPremium] = useState<boolean>(false);
  const [children, setChildren] = useState<Partner[]>([]);
  const [totalPartners, setTotalPartners] = useState(0);
  const [totalActiveLoans, setTotalActiveLoans] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);


  useEffect(() => {
    const fetchPartnerDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          "/api/partners/get-partner-details.php",
          {
            user_id: userId,
          }
        );
        setPartner(response.data.partner);
        setChildren(response.data.children);
        setTotalPartners(response.data.total_partners);
        setTotalActiveLoans(response.data.total_active_loans);
        setIsSubscribed(response.data.is_subscribed);
        setFollowerCount(response.data.follower_count);
        setAvatarUrl(response.data.partner.avatar_url);
        setPremium(response.data.premium_status);
      } catch (error) {
        console.error("Error fetching partner details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetails();
  }, [userId]);

  const handleSubscribe = async () => {
    const action = isSubscribed ? "unsubscribe" : "subscribe";
    try {
      const response = await axios.post("/api/partners/subscribe.php", {
        user_id: userId,
        action: action,
      });
      if(action === "subscribe"){
        dispatch(updateBonusBalance(response.data.bonus_added));
      }
      setIsSubscribed(!isSubscribed);
      setFollowerCount((prev) => prev + (isSubscribed ? -1 : 1));
    } catch (error) {
      console.error(`Error trying to ${action}:`, error);
    }
  };

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleAvatarUpdate = (newAvatarUrl: string) => {
    setAvatarUrl(newAvatarUrl);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      {loading ? (
        <Spin size="large" />
      ) : partner ? (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Card
                style={{ marginBottom: 10 }}
                actions={
                  userData?.id === Number(userId)
                    ? [<UploadOutlined key="edit"  onClick={showModal} />]
                    : []
                }
              >
                <Card.Meta
                  avatar={
                    <AvatarUpload
                      userId={Number(userId)}
                      avatarUrl={avatarUrl}
                      onUpdate={handleAvatarUpdate}
                      modal={false}
                    />
                  }
                  title={`${partner.first_name} ${partner.last_name}`}
                  description={
                    <div className="profile-item">
                      {premium && <Tag color="#ffd100">{t("Premium")}</Tag>}
                      <span>
                        {t("Followers")}: {formatViewCount(followerCount)}
                      </span>
                      <span>
                        {t("Total Partners")}: {formatViewCount(totalPartners)}
                      </span>
                      {userData?.id !== Number(userId) && (
                        <span>
                          {t("Total Active Loans")}:{" "}
                          {totalActiveLoans !== null ? (
                            `$${totalActiveLoans.toFixed(2)}`
                          ) : (
                            <Link onClick={() => navigate("/premium/")}>
                              {t("Get premium status")}
                            </Link>
                          )}
                        </span>
                      )}
                    </div>
                  }
                />
              </Card>
            </Col>
          </Row>
          {userData?.id !== Number(userId) && (
            <OtherUserActions
              partner={partner}
              isSubscribed={isSubscribed}
              children={children}
              handleSubscribe={handleSubscribe}
              activeTabKey={activeTabKey}
              onTabChange={onTabChange}
            />
          )}
          {userData?.id === Number(userId) && <Feed userId={userData!.id} />}
          <Modal
            title={t("Change Avatar")}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <AvatarUpload
              userId={Number(userId)}
              avatarUrl={avatarUrl}
              onUpdate={(newAvatarUrl) => {
                handleAvatarUpdate(newAvatarUrl);
                handleOk();
              }}
              modal={true}
            />
          </Modal>
        </>
      ) : (
        <p>{t("Partner not found")}</p>
      )}
    </div>
  );
};

export default PartnerDetails;
