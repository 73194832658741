import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Table, Typography } from "antd";

const { Title, Link, Paragraph } = Typography;

const rewardData = [
  {
    key: "1",
    level: "1",
    partnerIncomePercentage: "20%",
    personalPurchases: "$10 – $500",
    teamPurchases: "<$1,000",
  },
  {
    key: "2",
    level: "2",
    partnerIncomePercentage: "10%",
    personalPurchases: "$501 – $1,000",
    teamPurchases: "$5,000 – $10,000",
  },
  {
    key: "3",
    level: "3",
    partnerIncomePercentage: "5%",
    personalPurchases: "$1,001 – $2,000",
    teamPurchases: "$10,000 – $15,000",
  },
  {
    key: "4",
    level: "4",
    partnerIncomePercentage: "3%",
    personalPurchases: "$2,001 – $3,000",
    teamPurchases: "$15,001 – $30,000",
  },
  {
    key: "5",
    level: "5",
    partnerIncomePercentage: "3%",
    personalPurchases: ">$3,000",
    teamPurchases: "$30,000",
  },
];

const Marketing: FC = () => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Level"),
      dataIndex: "level",
      key: "level",
    },
    {
      title: t("% from partners' income"),
      dataIndex: "partnerIncomePercentage",
      key: "partnerIncomePercentage",
    },
    {
      title: t("Personal investments"),
      dataIndex: "personalPurchases",
      key: "personalPurchases",
    },
    {
      title: t("Team investments"),
      dataIndex: "teamPurchases",
      key: "teamPurchases",
    },
  ];

  return (
    <div className="marketing-container">
      <Title level={5}>
        {t("marketing.earn_interest")}
      </Title>
      <Paragraph>{t("marketing.additional_income")}</Paragraph>
      <Paragraph>{t("marketing.income_depends")}</Paragraph>
      <Paragraph>{t("marketing.new_rules")}</Paragraph>
      <div className="table-container">
        <Table dataSource={rewardData} columns={columns} pagination={false} />
      </div>
      <Paragraph strong>{t("marketing.total_income")}</Paragraph>
      <Link href="https://webtransfer.com/for_affiliates">More details...</Link>
    </div>
  );
};

export default Marketing;
