import { FC, useState, useEffect } from "react";
import { useTonConnectUI, useTonAddress } from "@tonconnect/ui-react";
import WebApp from "@twa-dev/sdk";
import { sendTransaction } from "../helpers/transactionService";
import { MainButton } from "@twa-dev/sdk/react";
import { useNavigate } from "react-router-dom";
import { getPaymentWallet } from "../helpers/api";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../services/store";
import {
  Select,
  InputNumber,
  Form,
  Typography,
  Checkbox,
  Card,
  List,
  message,
  Alert,
} from "antd";
import { RightCircleTwoTone, BulbTwoTone } from "@ant-design/icons";

const { Title, Text, Link, Paragraph } = Typography;
const { Option } = Select;

const LoanOvernight: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [tokenType, setTokenType] = useState<string>("USDT");
  const { userData } = useSelector((state: RootState) => state.user);
  const { wallet_address } = useSelector((state: RootState) => state.usdt);
  const [insurance, setInsurance] = useState<boolean>(true);
  const [messageText, setMessageText] = useState<React.ReactNode>("");
  const rate = insurance
    ? userData?.settings.max_rate_overnight
    : userData?.settings.max_rate_overnight_high_risk;

  useEffect(() => {
    if (amount && rate) {
      const profit = amount * (Number(rate) / 100);
      const insuranceAmount = insurance ? profit * 0.25 : profit * 0.05;
      const total = profit - insuranceAmount;

      setMessageText(
        <>
          {t("Your profit will be")}:
          <span style={{ color: "green", marginLeft: 10 }}>
            +{total.toFixed(2)} {tokenType}
          </span>
        </>
      );
    }
  }, [amount, rate, insurance, t, tokenType]);

  const handleSubmit = async () => {
    if (!userFriendlyAddress) {
      message.error(t("Please connect your wallet"));
      return;
    }
    if (!amount || amount <= 0) {
      message.error(t("Please enter valid amount"));
      return;
    }

    setLoading(true);

    try {
      if (userData && userFriendlyAddress) {
        const term = 1;
        const marginLoan = 0;

        let comment;
        if (tokenType === "USDT") {
          comment = `UserId:${
            userData!.id
          };Rate:${rate};Term:${term};Insurance:${insurance};Margin:${marginLoan}`;
        } else {
          comment = `UserId:${
            userData!.id
          };Rate:${rate};Term:${term};Insurance:${insurance}`;
        }

        const walletResponse = await getPaymentWallet(userFriendlyAddress);

        if (walletResponse && walletResponse.walletAddress) {
          const myTransaction = await sendTransaction(
            comment,
            tokenType,
            wallet_address,
            walletResponse.walletAddress,
            amount
          );
          if (myTransaction) {
            const result = await tonConnectUI.sendTransaction(myTransaction);
            navigate("/");
          } else {
            console.error("Transaction could not be created.");
          }
        } else {
          console.error("Failed to fetch payment wallet from server.");
        }
      }
    } catch (error) {
      console.error("Error submitting form", error);
    } finally {
      setLoading(false);
    }
  };

  const selectBefore = (
    <Select
      value={tokenType}
      onChange={(value) => setTokenType(value)}
      style={{ width: 100 }}
    >
      <Option value="USDT">
        <img src="./assets/UsdtLogo.webp" alt="USDT Logo" width={12} /> USDT
      </Option>
      <Option value="TON">
        <img src="./assets/tonIcon.png" alt="TON Logo" width={12} /> TON
      </Option>
    </Select>
  );

  const features = [
    t("overnight.features.short_duration"),
    t("overnight.features.floating_interest_rate"),
    t("overnight.features.full_insurance"),
    t("overnight.features.profit_commission"),
  ];

  return (
    <div>
      <Title level={3}>{t("Overnight Loan")}</Title>
      <Text>
        {t("Current_rate")}: {rate}% {t("in_day")}
      </Text>
      {messageText && (
        <Text style={{ marginTop: "16px", display: "block" }}>
          {messageText}
        </Text>
      )}
      <Form.Item extra={`Min: ${tokenType === "USDT" ? "10 USDT" : "1 TON"}`}>
        <InputNumber
          value={amount}
          addonBefore={selectBefore}
          onChange={(value) => setAmount(value)}
          placeholder={t("Enter amount")}
          min={tokenType === "USDT" ? 10 : 1}
          style={{ marginTop: "10px" }}
          disabled={loading}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={insurance}
          onChange={() => {
            setInsurance(!insurance);
          }}
        >
          {t("To insure a loan")}{" "}
          <Link href="https://webtransfer.com/wt_insurance" target="_blank">
            {t("Learn more...")}
          </Link>
        </Checkbox>
      </Form.Item>
      {amount &&
        ((amount > 200 && tokenType === "USDT") ||
          (amount > 50 && tokenType === "TON")) && (
          <Alert
            message={
              <>
                {t("overnight.invest_massage")}{" "}
                <span style={{color: "#1677ff"}}
                  onClick={() =>
                    WebApp.openTelegramLink(
                      `https://t.me/webtransfercredit/203`
                    )
                  }
                >
                {t("Learn more...")}
                </span>
              </>
            }
            type="info"
            action={<BulbTwoTone style={{ fontSize: "16px" }} />}
            style={{ marginBottom: "10px" }}
          />
        )}
      <Alert
        message={t("overnight.partner_massage")}
        type="info"
        showIcon
        action={<RightCircleTwoTone style={{ fontSize: "16px" }} />}
        onClick={() => navigate("/loan-overnight/partners")}
      />
      <Card className="overnight-loans-card" bordered>
        <Typography>
          <Title level={5}>{t("overnight.title")}</Title>
          <Paragraph>{t("overnight.description")}</Paragraph>
          <Title level={5}>{t("overnight.key_features")}</Title>
          <Paragraph>{t("overnight.ps")}</Paragraph>
          <List
            dataSource={features}
            renderItem={(item) => (
              <List.Item>
                <Paragraph>{item}</Paragraph>
              </List.Item>
            )}
          />
        </Typography>
        <Link href="https://webtransfer.com/overnight" target="_blank">
          {t("Learn more...")}
        </Link>
      </Card>
      <MainButton
        text={t("to issue a loan")}
        onClick={handleSubmit}
        progress={loading}
      />
    </div>
  );
};

export default LoanOvernight;
